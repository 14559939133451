import { NgxLoggerLevel } from "ngx-logger";
import { commons } from "./environment.commons";

const loggerLevel = 'INFO' as keyof typeof NgxLoggerLevel;
const parsedLoggerLevel = NgxLoggerLevel[loggerLevel] ?? NgxLoggerLevel.ERROR;

export const environment = {
    firebaseKeys: {
        apiKey: "AIzaSyA8pameJ4hr7sAQ3yOYbezTizVfmrhSQUk",
        authDomain: "pe-gcp-customercare-02.firebaseapp.com",
        databaseURL: "https://pe-gcp-customercare-02-default-rtdb.firebaseio.com",
        projectId: "pe-gcp-customercare-02",
        storageBucket: "pe-gcp-customercare-02.appspot.com",
        messagingSenderId: "52415902569",
        appId: "1:52415902569:web:074df110c5b005912743f8",
        measurementId: "G-PEHB8D4PPB"
    },
    API_ACCOUNT: 'https://api.cc-irdigital.pe/aria/aria-api-accounts',
    API_CATALOG: 'https://api.cc-irdigital.pe/aria/aria-api-catalog',
    API_REPORT: 'https://api.cc-irdigital.pe/rex/rex-api-reports',
    API_REX: 'https://api.cc-irdigital.pe/rex/rex-api-requests',
    CUSTOMER_API: 'https://api.cc-irdigital.pe/autoatencion/cc-api-customers',
    ORDERS_API: 'https://api-order-prd.cc.cloudintercorpretail.pe',
    UBIGEO_API: 'https://ubigeo-dev.cc.cloudintercorpretail.pe',
    uploadBucket: 'pe-indigitalxp-rex-files-dev',
    timeToastr: 6000,
    loggerLevel: parsedLoggerLevel,
    commons
};